/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type FEATURE_FLAG = "ALTERNATE_OLDEST_FIRST_VIEW" | "AVATARS" | "DEFAULT_QA_STORY_MODE" | "DISABLE_LIVE_UPDATES" | "DISABLE_WARN_USER_OF_TOXIC_COMMENT" | "DISCUSSIONS" | "EMAIL_META" | "ENABLE_AMP" | "ENABLE_QA" | "ENABLE_RATINGS_AND_REVIEWS" | "EXTERNAL_MEDIA" | "FLATTEN_REPLIES" | "NEW_COMMENT_COUNT" | "READ_MORE_NEW_TAB" | "REDUCED_SECURITY_MODE" | "RTE_SARCASM" | "SECTIONS" | "SITE_MODERATOR" | "VIEWER_COUNT" | "%future added value";
export type SectionSelectorContainer_query = {
    readonly sections: ReadonlyArray<string> | null;
    readonly settings: {
        readonly featureFlags: ReadonlyArray<FEATURE_FLAG>;
    };
    readonly " $refType": "SectionSelectorContainer_query";
};
export type SectionSelectorContainer_query$data = SectionSelectorContainer_query;
export type SectionSelectorContainer_query$key = {
    readonly " $data"?: SectionSelectorContainer_query$data;
    readonly " $fragmentRefs": FragmentRefs<"SectionSelectorContainer_query">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SectionSelectorContainer_query",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "sections",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "concreteType": "Settings",
            "kind": "LinkedField",
            "name": "settings",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "featureFlags",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Query",
    "abstractKey": null
} as any);
(node as any).hash = '5421300ea96170bc90857beb7af22952';
export default node;
