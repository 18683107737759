/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AdvancedConfigContainer_settings = {
    readonly customCSSURL: string | null;
    readonly live: {
        readonly enabled: boolean;
    };
    readonly stories: {
        readonly scraping: {
            readonly enabled: boolean;
            readonly proxyURL: string | null;
            readonly customUserAgent: string | null;
            readonly authentication: boolean;
            readonly username: string | null;
            readonly password: string | null;
        };
        readonly disableLazy: boolean;
    };
    readonly " $fragmentRefs": FragmentRefs<"CommentStreamLiveUpdatesContainer_settings">;
    readonly " $refType": "AdvancedConfigContainer_settings";
};
export type AdvancedConfigContainer_settings$data = AdvancedConfigContainer_settings;
export type AdvancedConfigContainer_settings$key = {
    readonly " $data"?: AdvancedConfigContainer_settings$data;
    readonly " $fragmentRefs": FragmentRefs<"AdvancedConfigContainer_settings">;
};



const node: ReaderFragment = (function () {
    var v0 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "enabled",
        "storageKey": null
    } as any);
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "AdvancedConfigContainer_settings",
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "customCSSURL",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "LiveConfiguration",
                "kind": "LinkedField",
                "name": "live",
                "plural": false,
                "selections": [
                    (v0 /*: any*/)
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "StoryConfiguration",
                "kind": "LinkedField",
                "name": "stories",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "StoryScrapingConfiguration",
                        "kind": "LinkedField",
                        "name": "scraping",
                        "plural": false,
                        "selections": [
                            (v0 /*: any*/),
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "proxyURL",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "customUserAgent",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "authentication",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "username",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "password",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "disableLazy",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "args": null,
                "kind": "FragmentSpread",
                "name": "CommentStreamLiveUpdatesContainer_settings"
            }
        ],
        "type": "Settings",
        "abstractKey": null
    } as any;
})();
(node as any).hash = '49937da50d9eca8c3121782e92fbefc2';
export default node;
